import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import AuctionList from './components/AuctionList';
import AuctionDetail from './components/AuctionDetail';
import FirmInfoComponent from './components/FirmInfo';

const AppRouter: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/auctions" element={<AuctionList />} />
            <Route path="/auction/:id" element={<AuctionDetail />} />
            <Route path="/firm/:ati_id" element={<FirmInfoComponent />} />
        </Routes>
    );
};

export default AppRouter;
