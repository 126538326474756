import React from 'react';
import styled from 'styled-components';
import { Auction } from '../services/auctionService';
import { theme } from '../styles/theme';

const InfoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: ${theme.spacing.medium};
`;

const InfoItem = styled.div`
    background-color: ${theme.colors.light};
    border-radius: ${theme.borderRadius};
    padding: ${theme.spacing.medium};
`;

const InfoTitle = styled.h3`
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.secondary};
    margin-bottom: ${theme.spacing.small};
`;

const InfoValue = styled.p`
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.dark};
    font-weight: bold;
    margin: 0;
`;

interface AuctionInfoProps {
    auction: Auction;
}

const AuctionInfo: React.FC<AuctionInfoProps> = ({ auction }) => {
    const formatDateTime = (dateTime: string): string => {
        const date = new Date(dateTime);
        return date.toLocaleString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const convertToRubles = (kopecks: number | null): string => {
        return kopecks === null ? 'Нет данных' : `${(kopecks / 100).toFixed(2)} ₽`;
    };

    return (
        <InfoGrid>
            <InfoItem>
                <InfoTitle>ID счета</InfoTitle>
                <InfoValue>{auction.invoice_id}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Источник</InfoTitle>
                <InfoValue>{auction.source_api}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Тип</InfoTitle>
                <InfoValue>{auction.type}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Статус</InfoTitle>
                <InfoValue>{auction.status}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Начальная ставка</InfoTitle>
                <InfoValue>{convertToRubles(auction.bidding.start_rate)}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Текущая ставка</InfoTitle>
                <InfoValue>{convertToRubles(auction.bidding.current_trc_bid)}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Лучшая ставка</InfoTitle>
                <InfoValue>{convertToRubles(auction.bidding.best_bid_kopecks)}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Время создания</InfoTitle>
                <InfoValue>{formatDateTime(auction.created_at)}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Время завершения</InfoTitle>
                <InfoValue>{formatDateTime(auction.end_time_utc)}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Тип груза</InfoTitle>
                <InfoValue>{auction.cargo.type || 'Не указан'}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Требуемый тоннаж</InfoTitle>
                <InfoValue>{auction.cargo.required_tonnage} т</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Количество паллет</InfoTitle>
                <InfoValue>{auction.cargo.required_pallets}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Температурный режим</InfoTitle>
                <InfoValue>{auction.cargo.temperature || 'Не указан'}</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>Тип кузова</InfoTitle>
                <InfoValue>{auction.vehicle_requirements.body_type}</InfoValue>
            </InfoItem>
            {auction.customer_name && (
                <InfoItem>
                    <InfoTitle>Заказчик</InfoTitle>
                    <InfoValue>{auction.customer_name}</InfoValue>
                </InfoItem>
            )}
            {auction.comment && (
                <InfoItem>
                    <InfoTitle>Комментарий</InfoTitle>
                    <InfoValue>{auction.comment}</InfoValue>
                </InfoItem>
            )}
        </InfoGrid>
    );
};

export default React.memo(AuctionInfo);
