import React, { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from './Button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAuctionData } from '../hooks/useAuctionData';
import AuctionHeader from './AuctionHeader';
import AuctionInfo from './AuctionInfo';
import { theme } from '../styles/theme';

const RouteTimeline = lazy(() => import('./RouteTimeline'));
const ActiveFirmsTable = lazy(() => import('./ActiveFirmsTable'));
const BidChart = lazy(() => import('./BidChart'));

const AuctionDetailWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: ${theme.spacing.large};
`;

const SectionTitle = styled.h2`
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.primary};
    margin-top: ${theme.spacing.xlarge};
    margin-bottom: ${theme.spacing.medium};
    border-bottom: 2px solid ${theme.colors.primary};
    padding-bottom: ${theme.spacing.small};
`;

const Section = styled.section`
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius};
    box-shadow: ${theme.boxShadow.small};
    padding: ${theme.spacing.large};
    margin-bottom: ${theme.spacing.large};
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
`;

const ErrorMessage = styled.p`
    color: ${theme.colors.danger};
    text-align: center;
    font-size: ${theme.fontSizes.large};
    margin-top: ${theme.spacing.xlarge};
`;

const SourceBadge = styled.span`
    background-color: ${theme.colors.info};
    color: ${theme.colors.white};
    padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    border-radius: ${theme.borderRadius};
    font-size: ${theme.fontSizes.small};
    margin-left: ${theme.spacing.medium};
`;

const StatusBadge = styled.span<{ status: string }>`
    background-color: ${props => {
    switch (props.status.toLowerCase()) {
        case 'new':
            return theme.colors.success;
        case 'quick':
            return theme.colors.warning;
        default:
            return theme.colors.secondary;
    }
}};
    color: ${theme.colors.white};
    padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    border-radius: ${theme.borderRadius};
    font-size: ${theme.fontSizes.small};
    margin-left: ${theme.spacing.medium};
`;

const AuctionDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { auction, loading, error } = useAuctionData(id);

    if (loading) {
        return (
            <LoadingWrapper>
                <ProgressSpinner />
            </LoadingWrapper>
        );
    }

    if (error) {
        return (
            <AuctionDetailWrapper>
                <Section>
                    <ErrorMessage>
                        <div>{error}</div>
                        <Button
                            variant="secondary"
                            icon="pi-arrow-left"
                            onClick={() => window.history.back()}
                            style={{ marginTop: '1rem' }}
                        >
                            Вернуться к списку аукционов
                        </Button>
                    </ErrorMessage>
                </Section>
            </AuctionDetailWrapper>
        );
    }

    if (!auction) {
        return <ErrorMessage>Аукцион не найден</ErrorMessage>;
    }

    return (
        <AuctionDetailWrapper>
            <Section>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AuctionHeader auction={auction} />
                    <SourceBadge>{auction.source_api}</SourceBadge>
                    <StatusBadge status={auction.status}>{auction.status}</StatusBadge>
                </div>
            </Section>

            <Section>
                <SectionTitle>Информация об аукционе</SectionTitle>
                <AuctionInfo auction={auction} />
            </Section>

            <Section>
                <SectionTitle>
                    Маршрут
                    {auction.route.total_distance > 0 &&
                        ` (${auction.route.total_distance} км)`
                    }
                </SectionTitle>
                <Suspense fallback={<LoadingWrapper><ProgressSpinner /></LoadingWrapper>}>
                    <RouteTimeline route={auction.route} />
                </Suspense>
            </Section>

            <Section>
                <SectionTitle>График ставок</SectionTitle>
                <Suspense fallback={<LoadingWrapper><ProgressSpinner /></LoadingWrapper>}>
                    <BidChart auction={auction} />
                </Suspense>
            </Section>

            <Button
                variant="secondary"
                icon="pi-arrow-left"
                onClick={() => window.history.back()}
            >
                Вернуться к списку аукционов
            </Button>
        </AuctionDetailWrapper>
    );
};


export default AuctionDetail;
