import { useState, useEffect } from 'react';
import { getAuctionById, Auction } from '../services/auctionService';

interface AuctionData {
    auction: Auction | null;
    loading: boolean;
    error: string | null;
}

export const useAuctionData = (id: string | undefined): AuctionData => {
    const [auctionData, setAuctionData] = useState<AuctionData>({
        auction: null,
        loading: true,
        error: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!id) {
                setAuctionData(prev => ({ ...prev, loading: false, error: 'ID не указан' }));
                return;
            }

            const numericId = parseInt(id);
            if (isNaN(numericId)) {
                setAuctionData(prev => ({ ...prev, loading: false, error: 'Некорректный формат ID' }));
                return;
            }

            try {
                const auctionResult = await getAuctionById(numericId);
                setAuctionData({
                    auction: auctionResult,
                    loading: false,
                    error: null,
                });
            } catch (error) {
                console.error(`Failed to fetch data for id ${id}:`, error);
                const errorMessage = error instanceof Error ? error.message : 'Не удалось загрузить данные аукциона';
                setAuctionData(prev => ({
                    ...prev,
                    loading: false,
                    error: errorMessage,
                }));
            }
        };

        fetchData();
    }, [id]);

    return auctionData;
};
