import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getAuctions, Auction } from '../services/auctionService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import { Button } from './Button';
import { FilterMatchMode } from 'primereact/api';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { addLocale } from 'primereact/api';
import { InputNumber } from "primereact/inputnumber";

interface Filters {
    global: { value: string | null; matchMode: FilterMatchMode };
    [key: string]: { value: any; matchMode: FilterMatchMode };
}

interface DateFilters {
    startDate: Date | null;
    endDate: Date | null;
}

const StyledDataTable = styled(DataTable)`
    .p-datatable-header {
        background-color: ${theme.colors.light};
        padding: ${theme.spacing.medium};
    }

    .p-column-header-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-paginator {
        .p-paginator-page {
            &.p-highlight {
                background-color: ${theme.colors.primary};
                color: ${theme.colors.white};
            }
        }
    }
`;

const AuctionListWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: ${theme.spacing.large};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.large};

    h1 {
        font-size: ${theme.fontSizes.xlarge};
        color: ${theme.colors.primary};
    }
`;

const FiltersWrapper = styled.div`
    display: flex;
    gap: ${theme.spacing.medium};
    margin-bottom: ${theme.spacing.large};
    flex-wrap: wrap;
`;

const FilterItem = styled.div`
    flex: 1;
    min-width: 200px;
`;

const StyledLink = styled(Link)`
    color: ${theme.colors.primary};
    text-decoration: none;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
`;

const SourceBadge = styled.span<{ source: string }>`
    background-color: ${props => {
    switch (props.source) {
        case 'X5':
            return theme.colors.primary;
        case 'ATrucks':
            return theme.colors.success;
        case 'LogistPro':
            return theme.colors.info;
        default:
            return theme.colors.secondary;
    }
}};
    color: ${theme.colors.white};
    padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    border-radius: ${theme.borderRadius};
    font-size: ${theme.fontSizes.small};
`;

const StatusBadge = styled.span<{ status: string }>`
    background-color: ${props => {
    switch (props.status.toLowerCase()) {
        case 'new':
            return theme.colors.success;
        case 'quick':
            return theme.colors.warning;
        default:
            return theme.colors.secondary;
    }
}};
    color: ${theme.colors.white};
    padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    border-radius: ${theme.borderRadius};
    font-size: ${theme.fontSizes.small};
`;

const DateTimeCell = styled.div`
    display: flex;
    flex-direction: column;

    .date {
        font-weight: bold;
    }

    .time {
        color: ${theme.colors.secondary};
        font-size: ${theme.fontSizes.small};
    }
`;

const AuctionList: React.FC = () => {
    const [auctions, setAuctions] = useState<Auction[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [filters, setFilters] = useState<Filters>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        type: { value: null, matchMode: FilterMatchMode.EQUALS },
        source_api: { value: null, matchMode: FilterMatchMode.EQUALS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        created_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
        end_time_utc: { value: null, matchMode: FilterMatchMode.DATE_IS },
    });
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const [error, setError] = useState<string | null>(null);

    const fetchAuctions = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getAuctions();
            setAuctions(data);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Произошла ошибка при загрузке данных';
            console.error('Failed to fetch auctions:', error);
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    }, []);


    useEffect(() => {
        fetchAuctions();
    }, [fetchAuctions]);

    const convertToRubles = useCallback((kopecks: number | null): string => {
        return kopecks === null ? 'Нет данных' : `${(kopecks / 100).toFixed(2)} ₽`;
    }, []);

    const formatDateTime = (value: string) => {
        if (!value || value === '0001-01-01T00:00:00Z') return 'Не указано';
        const date = new Date(value);
        return (
            <DateTimeCell>
                <span className="date">{format(date, 'dd.MM.yyyy', { locale: ru })}</span>
                <span className="time">{format(date, 'HH:mm', { locale: ru })}</span>
            </DateTimeCell>
        );
    };

    const sourceBadgeTemplate = (rowData: Auction) => (
        <SourceBadge source={rowData.source_api}>{rowData.source_api}</SourceBadge>
    );

    const statusBadgeTemplate = (rowData: Auction) => (
        <StatusBadge status={rowData.status}>{rowData.status}</StatusBadge>
    );

    const linkBodyTemplate = (rowData: Auction) => (
        <StyledLink to={`/auction/${rowData.id}`}>
            {rowData.id}
        </StyledLink>
    );

    const bidBodyTemplate = (rowData: Auction) => (
        <div>
            <div>{convertToRubles(rowData.bidding.start_rate)}</div>
            {rowData.bidding.current_trc_bid && (
                <div style={{ color: theme.colors.secondary, fontSize: theme.fontSizes.small }}>
                    Текущая: {convertToRubles(rowData.bidding.current_trc_bid)}
                </div>
            )}
        </div>
    );

    const cargoTemplate = (rowData: Auction) => (
        <div>
            <div>{rowData.cargo.type || 'Не указан'}</div>
            <div style={{ color: theme.colors.secondary, fontSize: theme.fontSizes.small }}>
                {rowData.cargo.required_tonnage} т / {rowData.cargo.required_pallets} пал
            </div>
            {rowData.cargo.temperature && (
                <div style={{ color: theme.colors.info, fontSize: theme.fontSizes.small }}>
                    {rowData.cargo.temperature}
                </div>
            )}
        </div>
    );

    const routeTemplate = (rowData: Auction) => {
        const points = rowData.route.points;
        if (!points.length) return 'Нет данных';

        return (
            <div>
                <div>{points[0].point_address}</div>
                <div style={{ color: theme.colors.secondary, fontSize: theme.fontSizes.small }}>
                    ↓ {points.length - 1} точк{points.length - 1 === 1 ? 'а' : 'и'}
                </div>
                <div>{points[points.length - 1].point_address}</div>
                {rowData.route.total_distance > 0 && (
                    <div style={{ color: theme.colors.info, fontSize: theme.fontSizes.small }}>
                        {rowData.route.total_distance} км
                    </div>
                )}
            </div>
        );
    };

    if (loading) {
        return (
            <LoadingWrapper>
                <ProgressSpinner />
            </LoadingWrapper>
        );
    }

    if (error) {
        return (
            <AuctionListWrapper>
                <Header>
                    <h1>Список аукционов</h1>
                    <Button variant="primary" icon="pi-refresh" onClick={fetchAuctions}>
                        Повторить загрузку
                    </Button>
                </Header>
                <div style={{
                    color: theme.colors.danger,
                    padding: theme.spacing.medium,
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    marginTop: theme.spacing.medium
                }}>
                    <i className="pi pi-exclamation-triangle" style={{ marginRight: '8px' }}></i>
                    {error}
                </div>
            </AuctionListWrapper>
        );
    }



    return (
        <AuctionListWrapper>
            <Header>
                <h1>Список аукционов</h1>
                <Button variant="primary" icon="pi-refresh" onClick={fetchAuctions}>
                    Обновить
                </Button>
            </Header>

            <FiltersWrapper>
                <FilterItem>
                    <InputText
                        placeholder="Глобальный поиск"
                        value={globalFilterValue}
                        onChange={(e) => {
                            const value = e.target.value;
                            setGlobalFilterValue(value);
                            setFilters(prev => ({
                                ...prev,
                                global: { value, matchMode: FilterMatchMode.CONTAINS }
                            }));
                        }}
                        style={{ width: '100%' }}
                    />
                </FilterItem>
                <FilterItem>
                    <Dropdown
                        options={[
                            { label: 'X5', value: 'X5' },
                            { label: 'ATrucks', value: 'ATrucks' },
                            { label: 'LogistPro', value: 'LogistPro' }
                        ]}
                        value={filters.source_api.value}
                        onChange={(e) => {
                            setFilters(prev => ({
                                ...prev,
                                source_api: { value: e.value, matchMode: FilterMatchMode.EQUALS }
                            }));
                        }}
                        placeholder="Источник"
                        style={{ width: '100%' }}
                    />
                </FilterItem>
                <FilterItem>
                    <Dropdown
                        options={[
                            { label: 'SPOT', value: 'SPOT' },
                            { label: 'BIDDING', value: 'BIDDING' }
                        ]}
                        value={filters.type.value}
                        onChange={(e) => {
                            setFilters(prev => ({
                                ...prev,
                                type: { value: e.value, matchMode: FilterMatchMode.EQUALS }
                            }));
                        }}
                        placeholder="Тип"
                        style={{ width: '100%' }}
                    />
                </FilterItem>
            </FiltersWrapper>

            <StyledDataTable
                value={auctions}
                paginator
                rows={rows}
                first={first}
                onPage={(e) => setFirst(e.first)}
                rowsPerPageOptions={[10, 25, 50]}
                filters={filters}
                globalFilterFields={['auction_id', 'invoice_id', 'cargo.type', 'customer_name']}
                emptyMessage="Аукционы не найдены"
                className="p-datatable-sm"
                sortMode="multiple"
                removableSort
                resizableColumns
                columnResizeMode="fit"
            >
                <Column
                    field="source_api"
                    header="Источник"
                    body={sourceBadgeTemplate}
                    sortable
                />
                <Column
                    field="id"
                    header="ID"
                    body={linkBodyTemplate}
                    sortable
                />
                <Column
                    field="type"
                    header="Тип"
                    sortable
                />
                <Column
                    field="status"
                    header="Статус"
                    body={statusBadgeTemplate}
                    sortable
                />
                <Column
                    field="bidding"
                    header="Ставка"
                    body={bidBodyTemplate}
                    sortable
                    sortField="bidding.start_rate"
                />
                <Column
                    field="cargo"
                    header="Груз"
                    body={cargoTemplate}
                    sortable
                    sortField="cargo.type"
                />
                <Column
                    field="route"
                    header="Маршрут"
                    body={routeTemplate}
                />
                <Column
                    field="created_at"
                    header="Создан"
                    body={(rowData) => formatDateTime(rowData.created_at)}
                    sortable
                />
                <Column
                    field="end_time_utc"
                    header="Завершение"
                    body={(rowData) => formatDateTime(rowData.end_time_utc)}
                    sortable
                />
            </StyledDataTable>
        </AuctionListWrapper>
    );
};

export default AuctionList;
