const API_BASE_URL = `${process.env.REACT_APP_API_URL}/auctions`;
const ATI_BASE_URL = `${process.env.REACT_APP_API_URL}/ati/active_firms`;
const FIRM_INFO_BASE_URL = `${process.env.REACT_APP_API_URL}/ati/firm`;

interface Coordinates {
    latitude: number;
    longitude: number;
}

export interface RoutePoint {
    sequence: number;
    point_id: string;
    point_type: string;
    point_place: string;
    point_address: string;
    time_zone_name: string;
    arrival_time: string;
    time_zone_offset: number;
    coordinates: Coordinates;
}

export interface Route {
    points: RoutePoint[];
    total_distance: number;
}

interface Bidding {
    start_rate: number;
    rate_step: number | null;
    best_bid_kopecks: number | null;
    current_trc_bid: number | null;
}

interface Cargo {
    type: string;
    required_tonnage: number;
    required_pallets: number;
    temperature: string;
    is_tail_lift: boolean;
}

interface VehicleRequirements {
    body_type: string;
}

export interface Auction {
    id: number;
    source_api: string;
    auction_id: number;
    invoice_id: number;
    is_actual: boolean;
    type: string;
    status: string;
    created_at: string;
    updated_at: string;
    end_time_utc: string;
    bidding: Bidding;
    route: Route;
    cargo: Cargo;
    vehicle_requirements: VehicleRequirements;
    creator_credentials: string;
    customer_name: string | null;
    comment: string | null;
    additional_info: string | null;
    url: string | null;
    region: string | null;
}

export interface ActiveFirm {
    id: number;
    firm_name: string;
    invoice_id: number;
    sequence: number;
    actual_trucks_count: number;
    ati_id: number;
    search_count: number;
    sum_count: number;
    trucks_count: number;
    created_at: string;
    updated_at: string;
}

export interface FirmInfo {
    ati_id: number;
    firm_name: string;
    ownership: string;
    full_name: string;
    firm_type: string;
    firm_type_id_int: number;
    fias_id: string;
    address: string;
    info_reference: string;
    passport_reference: string;
    score: number;
    inn: string;
    last_month_active_days: number;
}

const handleResponse = async (response: Response) => {
    if (!response.ok) {
        // Пытаемся получить детали ошибки из ответа
        try {
            const errorData = await response.json();
            throw new Error(
                `API Error: ${response.status} ${response.statusText}. ${JSON.stringify(errorData)}`
            );
        } catch (e) {
            // Если не удалось распарсить JSON, используем стандартное сообщение
            throw new Error(
                `API Error: ${response.status} ${response.statusText}`
            );
        }
    }
    return response.json();
};

export const getAuctions = async (): Promise<Auction[]> => {
    try {
        const response = await fetch(API_BASE_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error('Error fetching auctions:', error);
        throw error;
    }
};

export const getAuctionById = async (id: number): Promise<Auction> => {
    try {
        const response = await fetch(`${API_BASE_URL}/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error(`Error fetching auction ${id}:`, error);
        throw error;
    }
};

export const getActiveFirmsByInvoiceId = async (invoice_id: number): Promise<ActiveFirm[]> => {
    try {
        const response = await fetch(`${ATI_BASE_URL}/${invoice_id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error(`Error fetching active firms for invoice ${invoice_id}:`, error);
        throw error;
    }
};

export const getFirmInfoByAtiId = async (ati_id: number): Promise<FirmInfo> => {
    try {
        const response = await fetch(`${FIRM_INFO_BASE_URL}/${ati_id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return handleResponse(response);
    } catch (error) {
        console.error(`Error fetching firm info for ATI ID ${ati_id}:`, error);
        throw error;
    }
};
